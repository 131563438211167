import React from 'react';
import styled from 'styled-components';
import { FaHeart, FaRegHeart, FaTrashAlt, FaEye } from 'react-icons/fa';

import { useAPI } from 'utils/api';

const Container = styled.div`
  border: 1px solid #5fd2da;
  border-radius: 16px;
  overflow: hidden;
  min-height: 116px;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  padding: 8px;
  background-color: #eafeff;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Image = styled.img`
  width: 100%;
`;

const Text = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #868686;
`;

const BtnGroup = styled.div`
  flex-basis: 38px;
  background-color: #c2f2f6;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FavoriteBtn = styled.div`
  width: 38px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
`;

const RemoveBtn = styled.div`
  width: 38px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
`;

const BtnWrapper = styled.div`
  width: 38px;
  height: 100%;
  text-align: center;
`;

const Recipe = (props) => {
  const {
    data = {},
    showFavorite = false,
    showRemove = false,
    showEyeIcon = false,
    onAfterRemove = () => {},
    onView = () => {},
  } = props;
  const api = useAPI();
  const imgUrl = data?.approved_photos ? data.approved_photos[0] : '';

  const handleViewRecipe = () => {
    onView();
  };

  const handleRemove = (e) => {
    e.stopPropagation();
    api.removeMyRecipe(data.id).then((res) => {
      alert(res.reason);
      onAfterRemove();
    });
  };

  return (
    <Container onClick={handleViewRecipe}>
      <Content>
        <Title>{data.name}</Title>

        {data.price !== undefined && <Text>預估金額：${data.price}</Text>}

        <Text>估計時間：{data.total_time}</Text>

        <Image src={imgUrl} />
      </Content>

      <BtnGroup>
        <FavoriteBtn>
          {showFavorite && data.is_myfavorite && <FaHeart color="red" />}
          {showFavorite && !data.is_myfavorite && <FaRegHeart color="red" />}
        </FavoriteBtn>

        {showEyeIcon ? <FaEye /> : <div>查看</div>}

        <RemoveBtn onClick={handleRemove}>
          {showRemove && <FaTrashAlt />}
        </RemoveBtn>
      </BtnGroup>
    </Container>
  );
};

export default Recipe;
