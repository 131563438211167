import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { decode } from 'js-base64';

import store from 'redux/store';
import {
  getDialogDefault,
  getDialogAIDefault,
  getPageInfo,
  getDialogListData,
  getGreeting,
  getShouldGoToBottom,
  getPrevPath,
  setPageInfo,
  setPrevPath,
  setRecipeList,
  setGreeting,
  setDialogListData,
  setShouldGoToBottom,
  setRecipeFromWhichMode,
} from 'redux/slices/_smartRecipe';

import HeaderBar from 'components/header-bar/header-bar-v2';
import BotDialog from 'components/smart-recipe/BotDialog';
import Recipe from 'components/smart-recipe/Recipe';
import Button from 'components/smart-recipe/Button';
import TextArea from 'components/UI/TextArea';
import UserDialog from 'components/smart-recipe/UserDialog';

import WebFn, { callAppFunc } from 'utils/jsbridge/index.js';
import { useAPI } from 'utils/api';
import {
  PATH_SMART_RECIPE_INFO,
  PATH_SMART_RECIPE,
  PATH_MY_RECIPE,
} from 'utils/constants/paths';
import {
  setRecipeIngredient,
  setRecipeIngredientReselect,
  setRecipeCuisineReselect,
  setRecipePageBack,
  setRecipeCuisine,
  setRecipeDish,
  setRecipeDishMore,
  setSelectRecipeByIngredient,
  setAskAiRecipe,
  setAiRecipeSearch,
  setRecipeCuisineReask,
} from 'utils/_ga.js';

import IconCheck from 'images/icons/check.svg';

const Container = styled.div`
  padding-top: 60px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`;

const DialogList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0 0 15px;
  gap: 30px 0;
`;

const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px 0;
  min-height: auto;
  padding: 0 0 90px;

  ${(props) =>
    props.isLastDialog &&
    css`
      min-height: calc(100dvh - 75px);
    `}

  ${(props) =>
    props.$mode === 'ai' &&
    css`
      padding: 0;
    `}

  ${(props) =>
    props.isLastDialog &&
    props.$mode === 'ai' &&
    css`
      min-height: 0;
      padding: 0 0 30px; ;
    `}
`;

const Dialog = styled.div`
  display: flex;
  flex-direction: column;
`;

const ResultWrapper = styled.div`
  margin-top: 15px;
`;

const OptionList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-right: 15px;
`;

const Option = styled.div`
  background-color: #eafeff;
  border: 1px solid #5fd2da;
  border-radius: 30px;
  padding: 8px 16px;
  position: relative;

  ${(props) =>
    props.active &&
    css`
      background-color: #5fd2da;
      color: #fff;
      padding: 8px 16px 8px 38px;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        background-image: url(${IconCheck});
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
    `}
`;

const SwiperRecipe = styled(Swiper)`
  &.swiper {
    padding-right: 15px;
    margin: 0;
  }

  .swiper-slide {
    width: 204px;
  }

  img {
    min-height: 106px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OperationBtnGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const AnswerList = styled.div`
  display: flex;
  flex-direction: column;
`;

const OpeningBtnGroup = styled.div`
  display: flex;
  gap: 0 10px;
  padding-right: 15px;

  > div {
    flex: 0 0 49%;
  }
`;

const OpeningRemarks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px 0;
`;

const InputMessageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: 15px;
`;

const StyledTextArea = styled(TextArea)`
  && {
    border-radius: 20px;
    width: 80%;
    padding: 10px;
    line-height: 20px;
  }
`;

const RecipeListWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 0;
`;

const UserDialogWrapper = styled.div`
  max-width: 80%;
  align-self: flex-end;
  margin-right: 15px;
`;

const MODE_NORMAL = 'NORMAL';
const MODE_AI = 'AI';

const SmartRecipe = () => {
  const api = useAPI();
  const pageName = '智能食譜頁';

  const dialogDefault = useSelector((state) => getDialogDefault(state));
  const dialogAIDefault = useSelector((state) => getDialogAIDefault(state));
  const dialogListFromStore = useSelector((state) => getDialogListData(state));
  const greeting = useSelector((state) => getGreeting(state));
  const shouldGoToBottom = useSelector((state) => getShouldGoToBottom(state));
  const prevPath = useSelector((state) => getPrevPath(state));

  const pageInfo = useSelector((state) => getPageInfo(state));
  const welcomeMsg = pageInfo.welcomeMsg;
  const myRecipeCount = pageInfo.totalRecipes;
  const optionList = pageInfo.optionList;
  const foodList = optionList?.map((opt) => opt.ingredient) ?? [];

  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [dialogList, setDialogList] = useState(dialogListFromStore);
  const [question, setQuestion] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [recommendedRecipe, setRecommendedRecipe] = useState([]);
  const [memberData, setMemberData] = useState({});

  const updateDialogList = (dialogIdx, data) => {
    const newDialogList = dialogList.map((d, idx) => {
      if (dialogIdx === idx) {
        return { ...d, ...data };
      }
      return d;
    });
    setDialogList(newDialogList);
  };

  const updateActiveIndexList = ({
    dialog,
    dialogIdx,
    rowIdx,
    activeIndex,
  }) => {
    let newList = [...dialog.recipeActiveIndexList];
    newList[rowIdx] = activeIndex;
    updateDialogList(dialogIdx, {
      recipeActiveIndexList: newList,
    });
  };

  // 滑動到頁面最下方
  const goToBottom = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth',
      });
    }, 100);
  };

  const getRandomGreeting = (list) => {
    const randomIdx = Math.floor(Math.random() * list.length);
    return list[randomIdx];
  };

  const chunkArray = (arr, chunkSize) => {
    if (!arr) return [];
    const resultArray = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      resultArray.push(arr.slice(i, i + chunkSize));
    }
    return resultArray;
  };

  const jsBridgeCallback = (strJson) => {
    const dataJson = JSON.parse(strJson);
    if (dataJson.fnName === 'sendMemberData') {
      const dataJsonString = decode(dataJson.params.data);
      const jsonData = JSON.parse(dataJsonString);
      setMemberData(jsonData);
    }
  };

  const fetchRecipeList = ({ dialog, dialogIdx, page = 1 }) => {
    setLoading(true);
    api
      .getRecipeList({
        item: dialog.food,
        type: dialog.type,
        isPageable: true,
        page,
      })
      .then((data) => {
        const listData = data.bean;
        if (listData.rows.length === 0) {
          alert('沒有更多的食譜了');
          return;
        }
        updateDialogList(dialogIdx, {
          food: dialog.food,
          type: dialog.type,
          recipeListData:
            listData.page === 1
              ? { ...listData, rows: [listData.rows] }
              : {
                  ...listData,
                  rows: [...dialog.recipeListData.rows, listData.rows],
                },
        });
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        setLoading(false);
        goToBottom();
      });
  };

  const fetchPageInfo = () => {
    api
      .fetchRecipeData()
      .then((data) => store.dispatch(setPageInfo(data)))
      .catch((err) => console.log(err));
  };

  const fetchAIRecipeSettings = () => {
    api
      .fetchAIRecipeSettings()
      .then((res) => {
        const greetingList = res[0].greetings;
        store.dispatch(setGreeting(getRandomGreeting(greetingList)));
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };

  // 選擇食材
  const handleClickIngredient = (dialogIdx, opt) => {
    setRecipeIngredient({ name: opt, pageName }); // GA
    if (dialogList[dialogIdx].food) return;
    updateDialogList(dialogIdx, { food: opt });
  };

  // 選擇菜系
  const handleClickCuisine = (dialog, dialogIdx, opt) => {
    setRecipeCuisine({ name: opt, pageName }); // GA
    if (dialogList[dialogIdx].type) return;
    updateDialogList(dialogIdx, { type: opt });
    fetchRecipeList({ dialog: { ...dialog, type: opt }, dialogIdx });
  };

  // 查看食譜
  const handleViewRecipe = (dialog, recipe) => {
    let newRecipeList = [];

    if (dialog.mode === MODE_NORMAL) {
      newRecipeList = dialog.recipeListData.rows.flat();
      store.dispatch(setRecipeFromWhichMode(MODE_NORMAL));
    }

    if (dialog.mode === MODE_AI) {
      newRecipeList = dialog.recipeListData;
      store.dispatch(setRecipeFromWhichMode(MODE_AI));
    }

    setRecipeDish({ dialog, recipe, recipeList: newRecipeList, pageName }); // GA
    store.dispatch(setRecipeList(newRecipeList));
    store.dispatch(setPrevPath([...prevPath, PATH_SMART_RECIPE]));
    store.dispatch(setDialogListData(dialogList));
    navigate(`${PATH_SMART_RECIPE_INFO}/${recipe.id}`);
  };

  // 上一頁
  const handleBack = () => {
    setRecipePageBack({ pageName }); // GA
    callAppFunc('backMain', {});
  };

  // 點擊 "依食材選擇食譜"、"個人化詢問食譜"
  const handleStart = (mode) => {
    if (mode === MODE_NORMAL) {
      setDialogList((state) => [...state, dialogDefault]);
    }
    if (mode === MODE_AI) {
      fetchAIRecipeSettings();
      setDialogList((state) => [...state, dialogAIDefault]);
    }
    goToBottom();
  };

  // 點擊 "重新選擇"、"重新詢問"
  const handleRestart = (dialog, dialogIdx) => {
    if (dialog.mode === MODE_NORMAL) {
      dialog.type
        ? setRecipeCuisineReselect({ pageName }) // GA
        : setRecipeIngredientReselect({ pageName }); // GA

      if (dialog.recipeListData.rows) {
        setDialogList((state) => [...state, _.cloneDeep(dialogDefault)]);
      } else {
        updateDialogList(dialogIdx, { ...dialogDefault });
      }
    }
    if (dialog.mode === MODE_AI) {
      setQuestion('');
      setDialogList((state) => [...state, { ...dialogAIDefault }]);
    }
    goToBottom();
  };

  // 點擊 "送出"
  const handleSendMessage = (dialogIdx) => {
    goToBottom();
    setQuestion('');
    updateDialogList(dialogIdx, { question });
    setLoading(true);

    api
      .sendAIRecipeMessage({
        user_uuid: memberData.uuid,
        user_message: question,
        chat_history: chatHistory.slice(0, 3),
        recommended_recipe: recommendedRecipe.map((recipe) => recipe.name),
      })
      .then((data) => {
        updateDialogList(dialogIdx, {
          question,
          answer: data.answer,
          recipeListData: data.recipes,
        });

        const newChatHistory = [
          { question, answer: data.answer },
          ...chatHistory,
        ];
        setChatHistory(newChatHistory);

        const newRecommendedRecipe = [...recommendedRecipe, ...data.recipes];
        setRecommendedRecipe(newRecommendedRecipe);

        setDialogList((state) => [...state, { ...dialogAIDefault }]);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        setLoading(false);
        goToBottom();
      });
  };

  // 看更多食譜
  const handleViewMore = (dialog, dialogIdx) => {
    if (dialog.mode === MODE_NORMAL) {
      setRecipeDishMore({ pageName }); // GA
      const nextPage = dialog.recipeListData?.page + 1;
      fetchRecipeList({ dialog: dialog, page: nextPage, dialogIdx });
    }
    if (dialog.mode === MODE_AI) {
      updateDialogList(dialogIdx, { showRecipeRow: dialog.showRecipeRow + 1 });
    }
    goToBottom();
  };

  // 進入 "我的食譜" 頁面
  const handleGoMyRecipe = () => {
    store.dispatch(setDialogListData(dialogList));
    store.dispatch(setPrevPath([...prevPath, PATH_SMART_RECIPE]));
    navigate(PATH_MY_RECIPE);
  };

  const handleSelectRecipeByIngredient = () => {
    setSelectRecipeByIngredient({ pageName }); // GA
    handleStart(MODE_NORMAL);
  };

  const handleAskAiRecipe = () => {
    setAskAiRecipe({ pageName }); // GA
    handleStart(MODE_AI);
  };

  const handleAiRecipeSearch = ({ terms, dialogIdx }) => {
    setAiRecipeSearch({ terms, pageName }); // GA
    handleSendMessage(dialogIdx);
  };

  // 頁面初始化
  useEffect(() => {
    if (dialogListFromStore.length === 0) {
      Promise.all([api.fetchRecipeData()])
        .then((values) => {
          setInitialLoading(false);

          const recipeData = values[0];
          store.dispatch(setPageInfo(recipeData));
        })
        .catch((err) => console.log(err));
    }
  }, [dialogListFromStore]);

  // 更新 "我的食譜" 數量
  useEffect(() => {
    fetchPageInfo();
  }, []);

  useEffect(() => {
    if (!shouldGoToBottom) return;
    goToBottom();
    store.dispatch(setShouldGoToBottom(false));
  }, [shouldGoToBottom]);

  // 抓取會員資料
  useEffect(() => {
    WebFn({ callback: jsBridgeCallback });
    callAppFunc('fetchMemberData', {});
  }, []);

  return (
    <Container>
      <HeaderBar
        title="智能食譜"
        showMyRecipe
        myRecipeCount={myRecipeCount}
        onGoMyRecipe={handleGoMyRecipe}
        onBack={handleBack}
      />

      <DialogList>
        {initialLoading && dialogListFromStore.length === 0 ? (
          <BotDialog loading />
        ) : (
          <>
            <OpeningRemarks>
              <BotDialog message={welcomeMsg} />
              <BotDialog message="請問您需要什麼服務呢？" />
            </OpeningRemarks>

            {dialogList.length === 0 && (
              <OpeningBtnGroup>
                <Button onClick={handleSelectRecipeByIngredient}>
                  依食材選擇食譜
                </Button>
                <Button onClick={handleAskAiRecipe}>個人化詢問食譜</Button>
              </OpeningBtnGroup>
            )}

            {dialogList.length > 0 &&
              dialogList.map((dialog, dialogIdx) => {
                const isFirstDialog = dialogIdx === 0;
                const isLastDialog = dialogList.length - 1 === dialogIdx;
                const firstAIDialogIdx = dialogList.findIndex(
                  (d) => d.mode === MODE_AI
                );
                const isFirstAIDialog =
                  firstAIDialogIdx !== 0 && firstAIDialogIdx === dialogIdx;

                if (dialog.mode === MODE_NORMAL) {
                  const showCheckMoreBtn =
                    dialog.recipeListData.rows?.flat().length <
                    dialog.recipeListData.total;

                  const showAssistantBtn = dialog.food && dialog.type;

                  let typeList = [];
                  if (optionList) {
                    typeList =
                      optionList.find((opt) => opt.ingredient === dialog.food)
                        ?.cuisine ?? [];
                  }

                  return (
                    <DialogWrapper
                      key={dialogIdx}
                      isFirstDialog={isFirstDialog}
                      isLastDialog={isLastDialog}
                    >
                      <Dialog>
                        <BotDialog message="請問您想要以什麼為主要食材的料理食譜呢？" />
                        <ResultWrapper>
                          <OptionList>
                            {foodList.map((opt, idx) => {
                              if (dialog.food && dialog.food !== opt) return;
                              return (
                                <Option
                                  key={idx}
                                  active={dialog.food === opt}
                                  onClick={() =>
                                    handleClickIngredient(dialogIdx, opt)
                                  }
                                >
                                  {opt}
                                </Option>
                              );
                            })}
                          </OptionList>
                        </ResultWrapper>
                      </Dialog>

                      {dialog.food && (
                        <Dialog>
                          <BotDialog message="您喜歡哪一國的料理呢？" />

                          <ResultWrapper>
                            <OptionList>
                              {typeList?.map((opt, idx) => {
                                if (dialog.type && dialog.type !== opt) return;
                                return (
                                  <Option
                                    key={idx}
                                    active={dialog.type === opt}
                                    onClick={() =>
                                      handleClickCuisine(dialog, dialogIdx, opt)
                                    }
                                  >
                                    {opt}
                                  </Option>
                                );
                              })}
                            </OptionList>
                          </ResultWrapper>
                        </Dialog>
                      )}

                      {dialog.recipeListData.rows && (
                        <Dialog>
                          <Row>
                            <BotDialog
                              message={
                                dialog.recipeListData.rows.length > 0
                                  ? `推薦您以下${dialog.type}${dialog.food}相關料理`
                                  : '查無符合食譜。'
                              }
                            />
                          </Row>

                          <AnswerList>
                            {dialog.recipeListData.rows.map((row, rowIdx) => {
                              const activeSlide =
                                dialog.recipeActiveIndexList[rowIdx];
                              return (
                                <ResultWrapper key={rowIdx}>
                                  <SwiperRecipe
                                    initialSlide={activeSlide}
                                    slidesPerView="auto"
                                    spaceBetween={8}
                                    onActiveIndexChange={(swiper) => {
                                      updateActiveIndexList({
                                        dialog,
                                        dialogIdx,
                                        rowIdx,
                                        activeIndex: swiper.activeIndex,
                                      });
                                    }}
                                  >
                                    {row.map((recipe, idx) => {
                                      return (
                                        <SwiperSlide key={idx}>
                                          <Recipe
                                            data={recipe}
                                            showFavorite
                                            onView={() => {
                                              handleViewRecipe(dialog, recipe);
                                            }}
                                          />
                                        </SwiperSlide>
                                      );
                                    })}
                                  </SwiperRecipe>
                                </ResultWrapper>
                              );
                            })}
                          </AnswerList>
                        </Dialog>
                      )}

                      {isLastDialog && loading && (
                        <Dialog>
                          <BotDialog
                            loading={loading}
                            loadingText="食譜產生中"
                          />
                        </Dialog>
                      )}

                      {isLastDialog && dialog.food !== '' && (
                        <OperationBtnGroup>
                          {showCheckMoreBtn && (
                            <Button
                              onClick={() => handleViewMore(dialog, dialogIdx)}
                            >
                              看更多食譜
                            </Button>
                          )}

                          <Button
                            onClick={() => handleRestart(dialog, dialogIdx)}
                          >
                            重新選擇
                          </Button>

                          {showAssistantBtn && (
                            <Button onClick={handleAskAiRecipe}>
                              個人化詢問食譜
                            </Button>
                          )}
                        </OperationBtnGroup>
                      )}
                    </DialogWrapper>
                  );
                }

                if (dialog.mode === MODE_AI) {
                  const recipeRowList = chunkArray(dialog.recipeListData, 10);
                  const hasMoreRecipes =
                    dialog.showRecipeRow < recipeRowList.length;

                  return (
                    <DialogWrapper
                      key={dialogIdx}
                      isFirstDialog={isFirstDialog}
                      isLastDialog={isLastDialog}
                      $mode="ai"
                    >
                      {isFirstDialog && (
                        <Dialog>
                          <BotDialog loading={!greeting} message={greeting} />
                        </Dialog>
                      )}

                      {isFirstAIDialog && (
                        <Dialog>
                          <BotDialog message="小幫手來了，有什麼需要協助的嗎？" />
                        </Dialog>
                      )}

                      {dialog.question && (
                        <Dialog>
                          <UserDialogWrapper>
                            <UserDialog message={dialog.question} />
                          </UserDialogWrapper>
                        </Dialog>
                      )}

                      {dialog.recipeListData && (
                        <>
                          <Dialog>
                            <BotDialog message={dialog.answer} />

                            {dialog.recipeListData.length > 0 && (
                              <RecipeListWrapper>
                                {recipeRowList.map((row, rowIdx) => {
                                  const activeSlide =
                                    dialog.recipeActiveIndexList[rowIdx];
                                  if (dialog.showRecipeRow < rowIdx + 1) return;
                                  return (
                                    <SwiperRecipe
                                      key={rowIdx}
                                      initialSlide={activeSlide}
                                      slidesPerView="auto"
                                      spaceBetween={8}
                                      onActiveIndexChange={(swiper) => {
                                        updateActiveIndexList({
                                          dialog,
                                          dialogIdx,
                                          rowIdx,
                                          activeIndex: swiper.activeIndex,
                                        });
                                      }}
                                    >
                                      {row.map((recipe, idx) => {
                                        return (
                                          <SwiperSlide key={idx}>
                                            <Recipe
                                              data={recipe}
                                              showFavorite
                                              onView={() => {
                                                handleViewRecipe(
                                                  dialog,
                                                  recipe
                                                );
                                              }}
                                            />
                                          </SwiperSlide>
                                        );
                                      })}
                                    </SwiperRecipe>
                                  );
                                })}
                              </RecipeListWrapper>
                            )}
                          </Dialog>

                          {hasMoreRecipes && (
                            <OperationBtnGroup>
                              <Button
                                onClick={() =>
                                  handleViewMore(dialog, dialogIdx)
                                }
                              >
                                看更多食譜
                              </Button>
                              {/* <Button
                            onClick={() => {
                              setRecipeCuisineReask({ pageName });
                              handleRestart(dialog);
                            }}
                          >
                            重新詢問
                          </Button>
                          <Button onClick={handleSelectRecipeByIngredient}>
                            依食材選擇食譜
                          </Button> */}
                            </OperationBtnGroup>
                          )}
                        </>
                      )}

                      {isLastDialog && !loading && (
                        <InputMessageWrapper>
                          <StyledTextArea
                            maxLength={50}
                            autoSize={{ minRows: 1 }}
                            placeholder="告訴我一些什麼吧"
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                          />

                          <Button
                            style={{ padding: '8px 15px' }}
                            disabled={!question}
                            onClick={() => {
                              handleAiRecipeSearch({
                                terms: question,
                                dialogIdx,
                              });
                            }}
                          >
                            送出
                          </Button>
                        </InputMessageWrapper>
                      )}

                      {isLastDialog && loading && (
                        <Dialog>
                          <BotDialog
                            loading={loading}
                            loadingText="食譜產生中"
                          />
                        </Dialog>
                      )}
                    </DialogWrapper>
                  );
                }
              })}
          </>
        )}
      </DialogList>
    </Container>
  );
};

export default SmartRecipe;
